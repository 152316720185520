import { z } from "zod";
import {
  dateOfBirthValidator,
  emailValidator,
  fullNameValidator,
  jobTitleValidator,
  positiveNumberMax1000,
  professionValidator,
  unitedStatesAddressSchema,
  zPhoneNumber,
} from "../shared";

export type PersonalDetails = z.infer<typeof personalDetailsFlowSchema>;

const details = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  profession: professionValidator,
  jobTitle: jobTitleValidator,
  phoneNumber: zPhoneNumber,
  email: emailValidator,
  primaryAddress: unitedStatesAddressSchema,
  sameAddress: z.boolean(),
  mailingAddress: unitedStatesAddressSchema.optional(),
  residenceDurationOfStay: positiveNumberMax1000,
});

export const personalDetailsFlowSchema = details;

export const partialPersonalDetailsFlowSchema = details.deepPartial();

export type PartialPersonalDetails = z.infer<
  typeof partialPersonalDetailsFlowSchema
>;
