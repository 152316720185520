import { z } from "zod";
import {
  emailValidator,
  jobTitleValidator,
  multiChoiceValidator,
  professionValidator,
  zipCodeValidator,
} from "../shared";

export const personalInformation = z.object({
  email: emailValidator,
  jobTitle: jobTitleValidator,
  profession: professionValidator,
});

const partialPersonalInformation = z.object({
  email: emailValidator.optional(),
  jobTitle: jobTitleValidator.optional(),
  profession: professionValidator.optional(),
});

export const ownerPersonalInformationSchema = personalInformation.extend({
  signingDevicesZip: z.object({
    zipCode1: zipCodeValidator,
    zipCode2: zipCodeValidator.optional(),
    zipCode3: zipCodeValidator.optional(),
  }),
});
// .superRefine((val, ctx) => {
//   const zipMap = new Map<string, string[]>();

//   Object.entries(val.signingDevicesZip).forEach(([field, zip]) => {
//     if (zip !== undefined) {
//       if (!zipMap.has(zip)) {
//         zipMap.set(zip, [field]);
//       } else {
//         zipMap.get(zip)?.push(field);
//       }
//     }
//   });

//   for (const [zip, fields] of zipMap) {
//     if (fields.length > 1) {
//       ctx.addIssue({
//         code: z.ZodIssueCode.custom,
//         message: `Duplicate zip code ${zip} found.`,
//         path: ["signingDevicesZip"],
//       });

//       fields.forEach((field) => {
//         ctx.addIssue({
//           code: z.ZodIssueCode.custom,
//           message: `This zip code is already used`,
//           path: ["signingDevicesZip", field],
//         });
//       });
//     }
//   }
// });

const ownerPartialPersonalInformationSchema = partialPersonalInformation.extend(
  {
    signingDevicesZip: z.object({
      zipCode1: zipCodeValidator.optional(),
      zipCode2: zipCodeValidator.optional(),
      zipCode3: zipCodeValidator.optional(),
    }),
  },
);
// .superRefine((val, ctx) => {
//   const zipMap = new Map<string, string[]>();

//   Object.entries(val.signingDevicesZip).forEach(([field, zip]) => {
//     if (zip !== undefined) {
//       if (!zipMap.has(zip)) {
//         zipMap.set(zip, [field]);
//       } else {
//         zipMap.get(zip)?.push(field);
//       }
//     }
//   });

//   for (const [zip, fields] of zipMap) {
//     if (fields.length > 1) {
//       ctx.addIssue({
//         code: z.ZodIssueCode.custom,
//         message: `Duplicate zip code ${zip} found.`,
//         path: ["signingDevicesZip"],
//       });

//       fields.forEach((field) => {
//         ctx.addIssue({
//           code: z.ZodIssueCode.custom,
//           message: `This zip code is already used`,
//           path: ["signingDevicesZip", field],
//         });
//       });
//     }
//   }
// });

const baseKeyHolderSchema = z.object({
  keyHolders: z.object({
    owner_held_amount: z.union([
      z.literal("1"),
      z.literal("2"),
      z.literal("3"),
    ]),
    owner: ownerPersonalInformationSchema,
    keyHolder1: personalInformation.optional(),
    keyHolder2: personalInformation.optional(),
  }),
  criminalInvestigation: multiChoiceValidator,
  felonyOrDishonesty: multiChoiceValidator,
  experiencedLosses: multiChoiceValidator,
  kidnappingOrThreat: multiChoiceValidator,
  engagePrivateSecurity: multiChoiceValidator,
  detailedDescription: z.string().trim().max(10_000).optional(),
});

export type KeyHolder = z.infer<typeof keyHolderSchema>;
export const keyHolderSchema = baseKeyHolderSchema.superRefine((val, ctx) => {
  const missing_description = !val.detailedDescription;
  if (
    (!!val.criminalInvestigation && missing_description) ||
    (!!val.felonyOrDishonesty && missing_description) ||
    (!!val.experiencedLosses && missing_description) ||
    (!!val.kidnappingOrThreat && missing_description) ||
    (!!val.engagePrivateSecurity && missing_description)
  ) {
    ctx.addIssue({
      code: "custom",
      message: `Please provide a detailed description of the circumstances surrounding the "Yes" answers.`,
      path: ["detailedDescription"],
    });
  }

  const emailMap = new Map<string, Array<string>>();
  const addEmail = (email: string | undefined, source: string) => {
    if (email) {
      if (!emailMap.has(email)) {
        emailMap.set(email, [source]);
      } else {
        emailMap.get(email)?.push(source);
      }
    }
  };

  addEmail(val.keyHolders.owner.email, "owner");
  if (val.keyHolders.keyHolder1?.email) {
    addEmail(val.keyHolders.keyHolder1.email, "keyHolder1");
  }
  if (val.keyHolders.keyHolder2?.email) {
    addEmail(val.keyHolders.keyHolder2.email, "keyHolder2");
  }

  for (const [, sources] of emailMap) {
    if (sources.length > 1) {
      sources.forEach((source) => {
        if (source !== "owner") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `This email is already used by ${sources
              .filter((s) => s !== source)
              .map((s) =>
                s === "owner"
                  ? "Named Insured"
                  : s === "keyHolder1"
                    ? "Key Holder 1"
                    : "Key Holder 2",
              )
              .join(", ")}`,
            path: ["keyHolders", source, "email"],
          });
        }
      });
    }
  }

  const owner_held_key_count = val.keyHolders.owner_held_amount;

  const zipcode1 = val.keyHolders.owner.signingDevicesZip.zipCode1;

  if (!zipcode1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `owner must provide at least one zip code`,
      path: ["keyHolders", "owner", "signingDevicesZip", "zipCode1"],
    });
  }

  switch (owner_held_key_count) {
    case "1":
      if (!val.keyHolders.owner.signingDevicesZip.zipCode1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Missing zip code 1",
          path: ["keyHolders", "owner", "signingDevicesZip", "zipCode1"],
        });
      }

      break;
    case "2":
      if (!val.keyHolders.keyHolder1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Missing key holder 1`,
          path: ["keyHolders", "keyHolder1"],
        });
      }

      if (!val.keyHolders.owner.signingDevicesZip.zipCode2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `owner must provide two zip codes`,
          path: ["keyHolders", "owner", "signingDevicesZip", "zipCode2"],
        });
      }
      break;
    case "3":
      if (!val.keyHolders.owner.signingDevicesZip.zipCode2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "expected zip code 2",
          path: ["keyHolders", "owner", "signingDevicesZip", "zipCode2"],
        });
      }

      if (!val.keyHolders.owner.signingDevicesZip.zipCode3) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "expected zip code 3",
          path: ["keyHolders", "owner", "signingDevicesZip", "zipCode3"],
        });
      }

      break;
    default:
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid key count`,
        path: ["keyHolders", "owner_held_amount"],
      });
      break;
  }
});

export const partialKeyHolderSchema = z
  .object({
    keyHolders: z
      .object({
        owner_held_amount: z.union([
          z.literal("1"),
          z.literal("2"),
          z.literal("3"),
        ]),
        owner: ownerPartialPersonalInformationSchema,
        keyHolder1: partialPersonalInformation.partial().optional(),
        keyHolder2: partialPersonalInformation.partial().optional(),
      })
      .superRefine((val, ctx) => {
        // Email validation logic here, moved from parent schema
        const emailMap = new Map<string, Array<string>>();

        const addEmail = (email: string | undefined, source: string) => {
          if (email) {
            if (!emailMap.has(email)) {
              emailMap.set(email, [source]);
            } else {
              emailMap.get(email)?.push(source);
            }
          }
        };

        if (val.owner.email) {
          addEmail(val.owner.email, "owner");
        }
        if (val.keyHolder1?.email) {
          addEmail(val.keyHolder1.email, "keyHolder1");
        }
        if (val.keyHolder2?.email) {
          addEmail(val.keyHolder2.email, "keyHolder2");
        }

        for (const [, sources] of emailMap) {
          if (sources.length > 1) {
            sources.forEach((source) => {
              if (source !== "owner") {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `This email is already used by ${sources
                    .filter((s) => s !== source)
                    .map((s) =>
                      s === "owner"
                        ? "Named Insured"
                        : s === "keyHolder1"
                          ? "Key Holder 1"
                          : "Key Holder 2",
                    )
                    .join(", ")}`,
                  path: [source, "email"],
                });
              }
            });
          }
        }
      }),
    criminalInvestigation: multiChoiceValidator,
    felonyOrDishonesty: multiChoiceValidator,
    experiencedLosses: multiChoiceValidator,
    kidnappingOrThreat: multiChoiceValidator,
    engagePrivateSecurity: multiChoiceValidator,
    detailedDescription: z.string().trim().max(10_000).optional(),
  })
  .partial();

export type PartialKeyHolder = z.infer<typeof partialKeyHolderSchema>;
