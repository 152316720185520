import { z } from "zod";
import {
  addressSchema,
  dateOfBirthValidator,
  fullNameValidator,
  multiChoiceValidator,
  positiveNumberMax1000,
  zipCodeValidator,
} from "../shared";

export type KeyHolderPersonalDetails = z.infer<
  typeof keyHolderPersonalDetailsFlowSchema
>;

const details = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  primaryAddress: addressSchema,
  sameAddress: z.boolean(),
  mailingAddress: addressSchema.optional(),
  signingDeviceZipCode: zipCodeValidator,
  criminalInvestigation: multiChoiceValidator,
  felonyOrDishonesty: multiChoiceValidator,
  experiencedLosses: multiChoiceValidator,
  kidnappingOrThreat: multiChoiceValidator,
  engagePrivateSecurity: multiChoiceValidator,
  detailedDescription: z.string().trim().max(10_000).optional(),
  residenceDurationOfStay: positiveNumberMax1000,
});

export const keyHolderPersonalDetailsFlowSchema = details.superRefine(
  (val, ctx) => {
    const missing_description = !val.detailedDescription;
    if (
      (!!val.criminalInvestigation && missing_description) ||
      (!!val.felonyOrDishonesty && missing_description) ||
      (!!val.experiencedLosses && missing_description) ||
      (!!val.kidnappingOrThreat && missing_description) ||
      (!!val.engagePrivateSecurity && missing_description)
    ) {
      ctx.addIssue({
        code: "custom",
        message: `Please provide a detailed description of the circumstances surrounding the "Yes" answers.`,
        path: ["detailedDescription"],
      });
    }
  },
);

export const partialKeyHolderPersonalDetailsFlowSchema = details.deepPartial();

export type PartialKeyHolderPersonalDetails = z.infer<
  typeof partialKeyHolderPersonalDetailsFlowSchema
>;
