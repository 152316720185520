import { z, ZodIssueCode } from "zod";
import { multiChoiceValidator } from "../shared";

const baseSpendingBehaviorSchema = z.object({
  sendBitcoinDuringPolicy: multiChoiceValidator,
  monthlySpendingLimit: z
    .union([
      z.literal("0%"),
      z.literal("10%"),
      z.literal("25%"),
      z.literal("50%"),
      z.literal("greater_than_50%"),
    ])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Please select a monthly spending limit",
    }),
  coolDownPeriod: z
    .union([z.literal("3_days"), z.literal("7_days")])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Please select a cool down period",
    }),
});

export type SpendingBehavior = z.infer<typeof spendingBehaviorSchema>;
export const spendingBehaviorSchema = baseSpendingBehaviorSchema.superRefine(
  (data, ctx) => {
    if (data.sendBitcoinDuringPolicy && data.monthlySpendingLimit === "0%") {
      return ctx.addIssue({
        code: ZodIssueCode.custom,
        message:
          "Since you plan to send bitcoin during the policy, you must select a monthly spending limit",
        path: ["monthlySpendingLimit"],
      });
    }
  },
);

export const partialSpendingBehavior = baseSpendingBehaviorSchema.partial();
export type PartialSpendingBehavior = z.infer<typeof partialSpendingBehavior>;
