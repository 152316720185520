import { z } from "zod";
import {
  addressSchema,
  dateOfBirthValidator,
  fullNameValidator,
  multiChoiceValidator,
} from "../shared";

/**
 * this will cover 3 forms: executive, digitial asset manager, and shareholder
 */
export type StakeholderPersonalDetails = z.infer<typeof stakeholderFlowSchema>;

const details = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  primaryAddress: addressSchema,
  criminalInvestigation: multiChoiceValidator,
  felonyOrDishonesty: multiChoiceValidator,
  experiencedLosses: multiChoiceValidator,
  kidnappingOrThreat: multiChoiceValidator,
  engagePrivateSecurity: multiChoiceValidator,
  detailedDescription: z.string().trim().max(10_000).optional(),
});

export const stakeholderFlowSchema = details.superRefine((val, ctx) => {
  const missing_description = !val.detailedDescription;
  if (
    (!!val.criminalInvestigation && missing_description) ||
    (!!val.felonyOrDishonesty && missing_description) ||
    (!!val.experiencedLosses && missing_description) ||
    (!!val.kidnappingOrThreat && missing_description) ||
    (!!val.engagePrivateSecurity && missing_description)
  ) {
    ctx.addIssue({
      code: "custom",
      message: `Please provide a detailed description of the circumstances surrounding the "Yes" answers.`,
      path: ["detailedDescription"],
    });
  }
});

export const partialStakeholderFlowSchema = details.deepPartial();

export type PartialStakeholderPersonalDetails = z.infer<
  typeof partialStakeholderFlowSchema
>;
