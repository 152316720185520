import { z } from "zod";
import {
  addressSchema,
  dateOfBirthValidator,
  emailValidator,
  fullNameValidator,
  jobTitleValidator,
  positiveNumberMax1000,
  professionValidator,
  zipCodeValidator,
  zPhoneNumber,
} from "../shared";

export type MainPersonalDetails = z.infer<typeof mainPersonalDetailformSchema>;

const details = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  email: emailValidator,
  phoneNumber: zPhoneNumber,
  profession: professionValidator,
  jobTitle: jobTitleValidator,
  primaryAddress: addressSchema,
  sameAddress: z.boolean(),
  mailingAddress: addressSchema.optional(),
  signingDeviceZipCode: zipCodeValidator,
  residenceDurationOfStay: positiveNumberMax1000,
});

export const mainPersonalDetailformSchema = details;

export const partialMainPersonalDetailformSchema = details.deepPartial();

export type PartialMainPersonalDetails = z.infer<
  typeof partialMainPersonalDetailformSchema
>;
