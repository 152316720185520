import { z } from "zod";
import {
  emailValidator,
  jobTitleValidator,
  multiChoiceValidator,
  professionValidator,
} from "../shared";

export const companyLeaderValidator = z.object({
  email: emailValidator,
  jobTitle: jobTitleValidator,
  profession: professionValidator,
  areas: z
    .string({
      required_error: "Areas are required",
    })
    .trim()
    .min(1, { message: "Areas are required" })
    .max(1_000, { message: "Areas is too long" }),
});

const roles = z
  .array(
    z
      .union([
        z.literal("executive"),
        z.literal("digital-asset-manager"),
        z.literal("shareholder-25"),
        z.literal("other"),
      ])
      .optional(),
  )
  .refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  });

const partialRoles = z.array(
  z
    .union([
      z.literal("executive"),
      z.literal("digital-asset-manager"),
      z.literal("shareholder-25"),
      z.literal("other"),
    ])
    .optional(),
);

const baseCompanyLeaderSchema = z.object({
  criminalInvestigation: multiChoiceValidator,
  felonyOrDishonesty: multiChoiceValidator,
  experiencedLosses: multiChoiceValidator,
  kidnappingOrThreat: multiChoiceValidator,
  engagePrivateSecurity: multiChoiceValidator,
  company_leaders: z
    .array(companyLeaderValidator.extend({ roles }))
    .superRefine((val, ctx) => {
      const emailMap = new Map<string, Array<number>>();

      val.forEach((leader, index) => {
        if (leader.email) {
          if (!emailMap.has(leader.email)) {
            emailMap.set(leader.email, [index]);
          } else {
            emailMap.get(leader.email)?.push(index);
          }
        }
      });

      for (const [, indices] of emailMap) {
        if (indices.length > 1) {
          indices.forEach((index) => {
            // dont show error for application owner
            if (index > 0) {
              ctx.addIssue({
                code: "custom",
                message: `This email is already used by Company Leader ${indices
                  .filter((i) => i !== index)
                  .map((i) => i + 1)
                  .join(", ")}`,
                path: [index, "email"],
              });
            }
          });
        }
      }
    }),
  detailedDescription: z.string().optional(),
});

export type CompanyLeaderDetails = z.infer<typeof companyLeaderDetailSchema>;
export const companyLeaderDetailSchema = baseCompanyLeaderSchema.superRefine(
  (val, ctx) => {
    const missing_description = !val.detailedDescription;
    if (
      (!!val.criminalInvestigation && missing_description) ||
      (!!val.felonyOrDishonesty && missing_description) ||
      (!!val.experiencedLosses && missing_description) ||
      (!!val.kidnappingOrThreat && missing_description) ||
      (!!val.engagePrivateSecurity && missing_description)
    ) {
      ctx.addIssue({
        code: "custom",
        message: `Description is required because you answered "Yes" to one of the questions above.`,
        path: ["detailedDescription"],
      });
    }

    const leaderCount = val.company_leaders.length;
    if (leaderCount < 3) {
      ctx.addIssue({
        code: "custom",
        message: "You must add at least three key holders.",
        path: ["company_leaders"],
      });
    }
  },
);

const deepPartialCompanyLeaderSchema = z.object({
  criminalInvestigation: z.boolean().optional(),
  felonyOrDishonesty: z.boolean().optional(),
  experiencedLosses: z.boolean().optional(),
  kidnappingOrThreat: z.boolean().optional(),
  engagePrivateSecurity: z.boolean().optional(),
  company_leaders: z
    .array(
      companyLeaderValidator
        .partial()
        .extend({ roles: partialRoles })
        .partial(),
    )
    .superRefine((val, ctx) => {
      if (val.length > 0) {
        const emailMap = new Map<string, Array<number>>();

        val.forEach((leader, index) => {
          if (leader.email) {
            if (!emailMap.has(leader.email)) {
              emailMap.set(leader.email, [index]);
            } else {
              emailMap.get(leader.email)?.push(index);
            }
          }
        });

        for (const [, indices] of emailMap) {
          if (indices.length > 1) {
            indices.forEach((index) => {
              // dont show error for application owner
              if (index > 0) {
                ctx.addIssue({
                  code: "custom",
                  message: `This email is already used by Company Leader ${indices
                    .filter((i) => i !== index)
                    .map((i) => i + 1)
                    .join(", ")}`,
                  path: [index, "email"],
                });
              }
            });
          }
        }
      }
    }),
  detailedDescription: z.string().optional(),
});

export const partialCompanyLeaderSchema = deepPartialCompanyLeaderSchema
  .partial()
  .superRefine((val, ctx) => {
    if (
      (!!val.criminalInvestigation ||
        !!val.felonyOrDishonesty ||
        !!val.experiencedLosses ||
        !!val.kidnappingOrThreat ||
        !!val.engagePrivateSecurity) &&
      !val.detailedDescription
    ) {
      ctx.addIssue({
        code: "custom",
        message: `Description is required because you answered "Yes" to one of the questions above...`,
        path: ["detailedDescription"],
      });
    }
  });

export type PartialCompanyLeaderDetails = z.infer<
  typeof partialCompanyLeaderSchema
>;
