import { createStore } from "zustand/vanilla";
import { formVariants } from "@trident/validators/app-form-validations/partial-forms";
import type {
  FormStatuses,
  EnterpriseFlowFormStatuses,
  PersonalFlowFormStatuses,
  KeyHolderFlowFormStatuses,
  StakeholderFlowFormStatuses,
  setPartialDataType,
} from "@trident/validators/app-form-validations/partial-forms";
import type { PartialGovernanceInfo } from "@trident/validators/app-form-validations/main/governance";
import type { PartialKeyHolder } from "@trident/validators/app-form-validations/personal/key_holders";
import type { PartialInsuranceNeeds } from "@trident/validators/app-form-validations/main/insurance_needs";
import type { PartialBeneficiaries } from "@trident/validators/app-form-validations/personal/beneficiaries";
import type { PartialSpendingBehavior } from "@trident/validators/app-form-validations/main/spending_behavior";
import type { PartialBusinessSummaryForm } from "@trident/validators/app-form-validations/main/business_summary";
import type { PartialCompanyLeaderDetails } from "@trident/validators/app-form-validations/main/company_leaders";
import type { PartialPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/personal";
import type { PartialMainPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/main";
import type { PartialKeyHolderPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/key_holder";
import type { PartialStakeholderPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/stakeholder";
import {
  businessSummaryDefaultValues,
  beneficiariesDefaultValues,
  companyLeaderDefaultValues,
  personalDetailsMainDefaultValues,
  insuranceNeedsDefaultValues,
  governanceDefaultValues,
  spendingBehaviorDefaultValues,
  whitelistAddressesDefaultValues,
  keyholdersDefaultValues,
  personalDetailsStakeholder,
  personalDetailsKeyHolder,
} from "~/shared/default-form-values";
import type { DefaultValues } from "react-hook-form";
import type { WhitelistAddresAndCancelation } from "@trident/validators/app-form-validations/main/whitelist";
import { useFormStore } from "~/providers/form-store";

export type AWInsuranceApplicationFlow =
  | "enterprise"
  | "personal"
  | "stakeholder"
  | "keyHolder";

export const identityStepViews = [
  "identityIntro",
  "identityResponsibilities",
  "identityKyc",
  "identitySuccess",
  "identityStatus",
] as const;

export const insuranceAppFlows = [
  "enterprise",
  "stakeholder",
  "keyHolder",
  "personal",
] as const;

export type AWInsuranceApplicationMainFlowStep =
  (typeof awInsuranceApplicationMainFlowSteps)[number];

export const awInsuranceApplicationMainFlowSteps = [
  "welcome",
  "glossary",
  "termsOfService",
  "start",
  "policyOwnerInfo",
  "businessSummary",
  "beneficiaries",
  "companyLeaderDetails",
  ...identityStepViews,
  "insuranceNeeds",
  "governance",
  "spendingBehavior",
  "whitelistAddresses",
  "upload",
  "submit",
  "payment",
  "stripePaymentOptions",
  "stripePaymentForm",
] as const;

export type AWInsuranceApplicationInvitedFlowStep =
  (typeof awInsuranceApplicationInvitedFlowSteps)[number];

export const awInsuranceApplicationInvitedFlowSteps = [
  "welcome",
  "termsOfService",
  "responsibilities",
  "personalInfo",
  "kyc",
  "identitySuccess",
] as const;

export type AWInsuranceApplicationPersonalFlowStep =
  (typeof awInsuranceApplicationPersonalFlowSteps)[number];

export const awInsuranceApplicationPersonalFlowSteps = [
  "welcome",
  "termsOfService",
  "glossary",
  "start",
  "policyOwnerInfo",
  "beneficiaries",
  "keyHolders",
  ...identityStepViews,
  "insuranceNeeds",
  "spendingBehavior",
  "whitelistAddresses",
  "upload",
  "submit",
  "payment",
  "stripePaymentOptions",
  "stripePaymentForm",
] as const;

export type allSteps =
  | (typeof awInsuranceApplicationPersonalFlowSteps)[number]
  | (typeof awInsuranceApplicationInvitedFlowSteps)[number]
  | (typeof awInsuranceApplicationMainFlowSteps)[number];

interface MainFlow {
  flow: "enterprise";
  steps: typeof awInsuranceApplicationMainFlowSteps;
}

interface PersonalFlow {
  flow: "personal";
  steps: typeof awInsuranceApplicationPersonalFlowSteps;
}

interface InvitedFlow {
  flow: "keyHolder" | "stakeholder";
  steps: typeof awInsuranceApplicationInvitedFlowSteps;
}

type FlowSteps = MainFlow | InvitedFlow | PersonalFlow;

type GetFormStatusesReturnType<T extends AWInsuranceApplicationFlow> =
  T extends "enterprise"
    ? EnterpriseFlowFormStatuses
    : T extends "personal"
      ? PersonalFlowFormStatuses
      : T extends "keyHolder"
        ? KeyHolderFlowFormStatuses
        : T extends "stakeholder"
          ? StakeholderFlowFormStatuses
          : never;

export interface FormMainStore {
  applicationID: string;
  flow: FlowSteps;
  formValues: {
    personalDetailsMain: PartialMainPersonalDetails;
    businessSummary: DefaultValues<PartialBusinessSummaryForm>;
    beneficiaries: PartialBeneficiaries;
    companyLeaderDetails: PartialCompanyLeaderDetails;
    personalDetailsStakeholder: PartialStakeholderPersonalDetails;
    personalDetailsKeyHolder: PartialKeyHolderPersonalDetails;
    personalDetailsPersonal: PartialPersonalDetails;
    insuranceNeeds: DefaultValues<PartialInsuranceNeeds>;
    governance: PartialGovernanceInfo;
    spendingBehavior: PartialSpendingBehavior;
    whitelistAddresses: WhitelistAddresAndCancelation;
    keyHolders: PartialKeyHolder;
  };
  progress: number;
  formStatuses: FormStatuses;
  actions: {
    hasCompletedSteps: (flow: AWInsuranceApplicationFlow) => boolean;
    stepCheck: (
      step: string,
      flow: AWInsuranceApplicationFlow | string,
    ) => boolean;
    setApplicationID: (applicationID: string) => void;
    getProgress: (currentStep: allSteps) => number;
    getFlowSteps: (
      flow: AWInsuranceApplicationFlow,
    ) =>
      | typeof awInsuranceApplicationMainFlowSteps
      | typeof awInsuranceApplicationInvitedFlowSteps
      | typeof awInsuranceApplicationPersonalFlowSteps
      | null;
    setStauses: (statuses: FormStatuses) => void;
    nextStep: (
      currentStep: string,
    ) =>
      | `/insurance-application/${AWInsuranceApplicationFlow}/${string}/${string}`
      | "/";
    setData: ({ step, data }: setPartialDataType) => void;
    setFlow: (flow: AWInsuranceApplicationFlow) => void;
    getFormStatuses: <T extends AWInsuranceApplicationFlow>(
      flow: T,
    ) => GetFormStatusesReturnType<T>;
    reset: () => void;
  };
}

export const createFormStore = () => {
  return createStore<FormMainStore>()((set, get) => ({
    applicationID: "", // this shouldnt be empty
    flow: {
      flow: "enterprise" as const,
      steps: awInsuranceApplicationMainFlowSteps,
    },
    formValues: {
      businessSummary: businessSummaryDefaultValues,
      companyLeaderDetails: companyLeaderDefaultValues,
      personalDetailsMain: personalDetailsMainDefaultValues,
      personalDetailsStakeholder: personalDetailsStakeholder,
      personalDetailsKeyHolder: personalDetailsKeyHolder,
      personalDetailsPersonal: personalDetailsMainDefaultValues,
      insuranceNeeds: insuranceNeedsDefaultValues,
      governance: governanceDefaultValues,
      spendingBehavior: spendingBehaviorDefaultValues,
      whitelistAddresses: whitelistAddressesDefaultValues,
      beneficiaries: beneficiariesDefaultValues,
      keyHolders: keyholdersDefaultValues,
    },
    formStatuses: {
      policyOwnerInfo: "incomplete",
      businessSummary: "incomplete",
      companyLeaderDetails: "incomplete",
      personalDetailsMain: "incomplete",
      personalDetailsExecAndAssetManager: "incomplete",
      personalDetailsStakeholder: "incomplete",
      personalDetailsKeyHolder: "incomplete",
      personalDetailsPersonal: "incomplete",
      personalDetailsShareholder: "incomplete",
      insuranceNeeds: "incomplete",
      governance: "incomplete",
      spendingBehavior: "incomplete",
      whitelistAddresses: "incomplete",
      beneficiaries: "incomplete",
      keyHolders: "incomplete",
      identity: "incomplete",
      identityIntro: "incomplete",
      identityKyc: "incomplete",
      identityStatus: "incomplete",
      upload: "incomplete",
      payment: "incomplete",
    },
    progress: get()?.actions.getProgress("welcome") ?? 0,
    actions: {
      setStauses: (statuses) => {
        set({
          formStatuses: statuses,
        });
      },
      hasCompletedSteps: (flow) => {
        switch (flow) {
          case "enterprise": {
            const statuses = get().actions.getFormStatuses(flow);
            return (
              statuses.policyOwnerInfo === "complete" &&
              statuses.businessSummary === "complete" &&
              statuses.companyLeaderDetails === "complete" &&
              statuses.insuranceNeeds === "complete" &&
              statuses.governance === "complete" &&
              statuses.spendingBehavior === "complete" &&
              statuses.whitelistAddresses === "complete" &&
              statuses.beneficiaries === "complete" &&
              statuses.identityIntro === "complete" &&
              statuses.identityKyc === "complete" &&
              statuses.identityStatus === "complete" &&
              statuses.upload === "complete"
            );
          }
          case "personal": {
            const statusesPersonal = get().actions.getFormStatuses(flow);
            return (
              statusesPersonal.policyOwnerInfo === "complete" &&
              statusesPersonal.beneficiaries === "complete" &&
              statusesPersonal.keyHolders === "complete" &&
              statusesPersonal.insuranceNeeds === "complete" &&
              statusesPersonal.spendingBehavior === "complete" &&
              statusesPersonal.whitelistAddresses === "complete" &&
              statusesPersonal.upload === "complete"
            );
          }
          case "keyHolder": {
            const statusesKeyHolder = get().actions.getFormStatuses(flow);
            return (
              statusesKeyHolder.personalDetailsKeyHolder === "complete" &&
              statusesKeyHolder.identity === "complete"
            );
          }
          case "stakeholder": {
            const statusesStakeholder = get().actions.getFormStatuses(flow);
            return (
              statusesStakeholder.personalDetailsStakeholder === "complete" &&
              statusesStakeholder.identity === "complete"
            );
          }
        }
      },

      getFlowSteps: (flow) => {
        if (flow === "enterprise") {
          return awInsuranceApplicationMainFlowSteps;
        } else if (flow === "personal") {
          return awInsuranceApplicationPersonalFlowSteps;
        } else if (flow === "keyHolder" || flow === "stakeholder") {
          return awInsuranceApplicationInvitedFlowSteps;
        } else {
          return null;
        }
      },

      setApplicationID: (applicationID) =>
        set({
          applicationID,
        }),

      reset: () => {
        set({
          progress: 0,
          formValues: {
            businessSummary: businessSummaryDefaultValues,
            companyLeaderDetails: companyLeaderDefaultValues,
            personalDetailsMain: personalDetailsMainDefaultValues,
            personalDetailsStakeholder: personalDetailsStakeholder,
            personalDetailsKeyHolder: personalDetailsKeyHolder,
            personalDetailsPersonal: personalDetailsMainDefaultValues,
            insuranceNeeds: insuranceNeedsDefaultValues,
            governance: governanceDefaultValues,
            spendingBehavior: spendingBehaviorDefaultValues,
            whitelistAddresses: whitelistAddressesDefaultValues,
            beneficiaries: beneficiariesDefaultValues,
            keyHolders: keyholdersDefaultValues,
          },
          formStatuses: {
            policyOwnerInfo: "incomplete",
            businessSummary: "incomplete",
            companyLeaderDetails: "incomplete",
            personalDetailsMain: "incomplete",
            personalDetailsKeyHolder: "incomplete",
            personalDetailsPersonal: "incomplete",
            personalDetailsStakeholder: "incomplete",
            insuranceNeeds: "incomplete",
            governance: "incomplete",
            spendingBehavior: "incomplete",
            whitelistAddresses: "incomplete",
            beneficiaries: "incomplete",
            keyHolders: "incomplete",
            identityIntro: "incomplete",
            upload: "incomplete",
            identity: "incomplete",
            identityKyc: "incomplete",
            identityStatus: "incomplete",
            payment: "incomplete",
          },
        });
      },

      getFormStatuses: <T extends AWInsuranceApplicationFlow>(flow: T) => {
        const statuses = get().formStatuses;
        switch (flow) {
          case "enterprise":
            return {
              policyOwnerInfo: statuses.policyOwnerInfo,
              businessSummary: statuses.businessSummary,
              companyLeaderDetails: statuses.companyLeaderDetails,
              insuranceNeeds: statuses.insuranceNeeds,
              governance: statuses.governance,
              spendingBehavior: statuses.spendingBehavior,
              whitelistAddresses: statuses.whitelistAddresses,
              beneficiaries: statuses.beneficiaries,
              identityIntro: statuses.identityIntro,
              identityKyc: statuses.identityKyc,
              identityStatus: statuses.identityStatus,
              upload: statuses.upload,
            } as GetFormStatusesReturnType<T>;
          case "personal":
            return {
              policyOwnerInfo: statuses.policyOwnerInfo,
              beneficiaries: statuses.beneficiaries,
              keyHolders: statuses.keyHolders,
              insuranceNeeds: statuses.insuranceNeeds,
              identityIntro: statuses.identityIntro,
              identityKyc: statuses.identityKyc,
              identityStatus: statuses.identityStatus,
              spendingBehavior: statuses.spendingBehavior,
              whitelistAddresses: statuses.whitelistAddresses,
              upload: statuses.upload,
            } as GetFormStatusesReturnType<T>;
          case "keyHolder":
            return {
              personalDetailsKeyHolder: statuses.personalDetailsKeyHolder,
              identity: statuses.identity,
            } as GetFormStatusesReturnType<T>;
          case "stakeholder":
            return {
              personalDetailsStakeholder: statuses.personalDetailsStakeholder,
              identity: statuses.identity,
            } as GetFormStatusesReturnType<T>;
          default:
            console.log("Invalid flow type");
            return {} as GetFormStatusesReturnType<T>;
        }
      },

      getProgress: (currentStep) => {
        const flow = get().flow.flow;

        if (
          currentStep === "welcome" ||
          currentStep === "termsOfService" ||
          currentStep === "responsibilities" ||
          currentStep === "glossary" ||
          currentStep === "start" ||
          currentStep === "submit"
        ) {
          return 0;
        }

        if (flow === "enterprise") {
          return (
            (awInsuranceApplicationMainFlowSteps.indexOf(
              currentStep as (typeof awInsuranceApplicationMainFlowSteps)[number],
            ) +
              1) /
            awInsuranceApplicationMainFlowSteps.length
          );
        } else if (flow === "personal") {
          return (
            (awInsuranceApplicationPersonalFlowSteps.indexOf(
              currentStep as (typeof awInsuranceApplicationPersonalFlowSteps)[number],
            ) +
              1) /
            awInsuranceApplicationPersonalFlowSteps.length
          );
        } else {
          return (
            (awInsuranceApplicationInvitedFlowSteps.indexOf(
              currentStep as (typeof awInsuranceApplicationInvitedFlowSteps)[number],
            ) +
              1) /
            awInsuranceApplicationInvitedFlowSteps.length
          );
        }
      },

      setData: ({ step, data }) => {
        const formValues = get().formValues;
        switch (step) {
          case formVariants.personalDetailsMain: {
            set({
              formValues: {
                ...formValues,
                personalDetailsMain: data,
              },
            });
            break;
          }
          case formVariants.businessSummary: {
            set({
              formValues: {
                ...formValues,
                businessSummary: data,
              },
            });
            break;
          }
          case formVariants.companyLeaderDetails: {
            set({
              formValues: {
                ...formValues,
                companyLeaderDetails: data,
              },
            });
            break;
          }
          case formVariants.personalDetailsStakeholder: {
            set({
              formValues: {
                ...formValues,
                personalDetailsStakeholder: data,
              },
            });
            break;
          }
          case formVariants.personalDetailsKeyHolder: {
            set({
              formValues: {
                ...formValues,
                personalDetailsKeyHolder: data,
              },
            });
            break;
          }
          case formVariants.personalDetailsPersonal: {
            set({
              formValues: {
                ...formValues,
                personalDetailsPersonal: data,
              },
            });
            break;
          }
          case formVariants.insuranceNeeds: {
            set({
              formValues: {
                ...formValues,
                insuranceNeeds: data,
              },
            });
            break;
          }
          case formVariants.governance: {
            set({
              formValues: {
                ...formValues,
                governance: data,
              },
            });
            break;
          }
          case formVariants.spendingBehavior: {
            set({
              formValues: {
                ...formValues,
                spendingBehavior: data,
              },
            });
            break;
          }
          case formVariants.whitelistAddresses: {
            set({
              formValues: {
                ...formValues,
                whitelistAddresses: data,
              },
            });
            break;
          }
          case formVariants.beneficiaries: {
            set({
              formValues: {
                ...formValues,
                beneficiaries: data,
              },
            });
            break;
          }
          case formVariants.keyHolders: {
            set({
              formValues: {
                ...formValues,
                keyHolders: data,
              },
            });
            break;
          }
          default: {
            console.error("Invalid form variant");
          }
        }
      },

      setFlow: (flow) => {
        switch (flow) {
          case "enterprise": {
            set({
              flow: {
                flow: "enterprise",
                steps: awInsuranceApplicationMainFlowSteps,
              },
            });
            break;
          }
          case "keyHolder":
          case "stakeholder": {
            set({
              flow: {
                flow,
                steps: awInsuranceApplicationInvitedFlowSteps,
              },
            });
            break;
          }
          case "personal": {
            set({
              flow: {
                flow: "personal",
                steps: awInsuranceApplicationPersonalFlowSteps,
              },
            });
            break;
          }
          default:
            console.error("Invalid flow type");
        }
      },

      stepCheck: (step, flow) => {
        if (flow === "enterprise") {
          return awInsuranceApplicationMainFlowSteps.includes(
            step as AWInsuranceApplicationMainFlowStep,
          );
        } else if (flow === "personal") {
          return awInsuranceApplicationPersonalFlowSteps.includes(
            step as AWInsuranceApplicationPersonalFlowStep,
          );
        } else if (flow === "keyHolder" || flow === "stakeholder") {
          return awInsuranceApplicationInvitedFlowSteps.includes(
            step as AWInsuranceApplicationInvitedFlowStep,
          );
        } else {
          return false;
        }
      },

      nextStep: (currentStep) => {
        const { flow, steps } = get().flow;
        const applicationID = get().applicationID;
        const hasCompletedSteps = get().actions.hasCompletedSteps(flow);
        switch (flow) {
          case "enterprise": {
            const check = get().actions.stepCheck(currentStep, flow);
            if (!check) {
              console.error("Invalid step");
              return "/";
            }

            const nextStep =
              hasCompletedSteps && currentStep === "start"
                ? "payment"
                : steps[
                    steps.indexOf(
                      currentStep as AWInsuranceApplicationMainFlowStep,
                    ) + 1
                  ] ?? "welcome";

            set({
              flow: {
                flow: "enterprise",
                steps,
              },
            });

            return `/insurance-application/${flow}/${nextStep}/${applicationID}`;
          }
          case "keyHolder":
          case "stakeholder": {
            const check = get().actions.stepCheck(currentStep, flow);

            if (!check) {
              console.error("Invalid step");
              return "/";
            }

            set({
              flow: {
                flow,
                steps,
              },
            });

            const nextStep =
              steps[
                steps.indexOf(
                  currentStep as AWInsuranceApplicationInvitedFlowStep,
                ) + 1
              ];
            if (!nextStep) return "/";
            return `/insurance-application/${flow}/${nextStep}/${applicationID}`;
          }
          case "personal": {
            const check = get().actions.stepCheck(currentStep, flow);
            if (!check) {
              console.error("Invalid step");
              return "/";
            }

            const nextStep =
              hasCompletedSteps && currentStep === "start"
                ? "payment"
                : steps[
                    steps.indexOf(
                      currentStep as AWInsuranceApplicationPersonalFlowStep,
                    ) + 1
                  ] ?? "welcome";

            set({
              flow: {
                flow: "personal",
                steps,
              },
            });
            return `/insurance-application/${flow}/${nextStep}/${applicationID}`;
          }
          default: {
            console.error("Invalid flow");
            return "/";
          }
        }
      },
    },
  }));
};

export const useSetFlow = () => useFormStore((state) => state.actions.setFlow);

export const useSetApplicationFormData = () =>
  useFormStore((state) => state.actions.setData);

export const useGetFlowInfo = () => useFormStore((state) => state.flow);
export const useFlow = () => useFormStore((state) => state.flow.flow);

export const useStepCheck = () =>
  useFormStore((state) => state.actions.stepCheck);

export const useNextStep = () =>
  useFormStore((state) => state.actions.nextStep);

export const useGetProgress = () => useFormStore((state) => state.progress);

export const useSetFormStatuses = () =>
  useFormStore((state) => state.actions.setStauses);
export const useGetFormStatuses = () =>
  useFormStore((state) => state.actions.getFormStatuses);
export const useResetFormValues = () =>
  useFormStore((state) => state.actions.reset);

export const useGetAppID = () => useFormStore((state) => state.applicationID);
export const useSetAppID = () =>
  useFormStore((state) => state.actions.setApplicationID);

// form states
export const useGetValues = () => useFormStore((state) => state.formValues);
export const useGetBusinessSummary = () =>
  useFormStore((state) => state.formValues.businessSummary);
export const useGetCompanyLeaderDetails = () =>
  useFormStore((state) => state.formValues.companyLeaderDetails);
export const useGetPersonalDetailsMain = () =>
  useFormStore((state) => state.formValues.personalDetailsMain);
export const useGetPersonalDetailsStakeholder = () =>
  useFormStore((state) => state.formValues.personalDetailsStakeholder);
export const useGetPersonalDetailsKeyHolder = () =>
  useFormStore((state) => state.formValues.personalDetailsKeyHolder);
export const useGetPersonalDetailsPersonal = () =>
  useFormStore((state) => state.formValues.personalDetailsPersonal);
export const useGetInsuranceNeeds = () =>
  useFormStore((state) => state.formValues.insuranceNeeds);
export const useGetGovernance = () =>
  useFormStore((state) => state.formValues.governance);
export const useGetSpendingBehavior = () =>
  useFormStore((state) => state.formValues.spendingBehavior);
export const useGetWhitelistAddresses = () =>
  useFormStore((state) => state.formValues.whitelistAddresses);
export const useGetBeneficiaries = () =>
  useFormStore((state) => state.formValues.beneficiaries);
export const useGetKeyHolders = () =>
  useFormStore((state) => state.formValues.keyHolders);
