import { bitcoinAddressValidation } from "@trident/validators/bitcoin-address-validation";
import { z } from "zod";

export type WhitelistAddresAndCancelation = z.infer<
  typeof whitelistAndCancelationAddressFormSchema
>;

export const whitelistValidation = z
  .array(
    z.object({
      nickname: z
        .string()
        .trim()
        .min(1, { message: "Nickname is required" })
        .max(20, { message: "Nickname must be less than 20 characters" }),
      address: bitcoinAddressValidation,
    }),
  )
  .superRefine((val, ctx) => {
    const addressMap = new Map<string, number>();
    val.forEach((address, index) => {
      if (addressMap.has(address.address)) {
        ctx.addIssue({
          code: "custom",
          message: "This address is already added",
          path: [index, "address"],
        });
      }
      addressMap.set(address.address, index);
    });
  });

export const whitelistAndCancelationAddressFormSchema = z.object({
  cancelationAddress: z.object({
    nickname: z
      .string()
      .trim()
      .min(1, { message: "Nickname is required" })
      .max(20, { message: "Nickname must be less than 20 characters" }),
    address: bitcoinAddressValidation,
  }),
  addresses: whitelistValidation,
});

export type WhitelistAddress = z.infer<typeof whitelistAddressFormSchema>;
export const whitelistAddressFormSchema = z.object({
  addresses: whitelistValidation,
});
